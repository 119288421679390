<template>
  <v-form>
    <v-text-field
      class="mr-2"
      label="Perfil"
      name="Perfil"
      placeholder="Perfil"
      v-model="role.role"
      :readonly="true"
    ></v-text-field>
    <v-data-table :headers="headers" :items="role.menus" hide-actions class="elevation-0">
      <template v-slot:items="props">
        <td style="display:none;">{{ props.item.idMenu }}</td>
        <td>{{ props.item.menu }}</td>
      </template>
    </v-data-table>
  </v-form>
</template>
<script>
import { ServicoPerfisAcesso } from "../../servicos/servicoPerfisAcesso"
const servicoPerfilAcesso = new ServicoPerfisAcesso()
export default {
  data: () => ({
    id: "",
    role: "",
    headers: [{ text: "Menu", align: "left", value: "menu" }]
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoPerfilAcesso.buscarPorId(this.id).then(res => {
        this.role = res.data
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
